import React, { useEffect, useRef, useState } from "react";
import { Tooltip } from "antd";
import classnames from "classnames";
import App from "next/app";
import Head from "next/head";
import Link from "next/link";
import Router, { useRouter } from "next/router";
import getConfig from "next/config";
import { Spin } from "antd";
import NProgress from "nprogress";
import "antd/lib/menu/style/index";

require("style/app.less");

import Header from "../components/header/Header";
import NavHeaderMobile from "../components/header/HeaderMobile";
import { UserProvider, useUserContext } from "../components/contexts/usercontext";
import { ModalProvider } from "../components/contexts/modalcontext";
import { SearchOverlayProvider } from "../components/contexts/searchOverlayContext";
import useMediaQuery from "../components/hooks/useMediaQuery";
import BannerWrapper from "../components/wrappers/BannerWrapper";
import { getCurrentMenu } from "../components/header/assets";
import DesktopTopMenu from "../components/header/DesktopTopMenu";

import Services from "../services";
import useGlobal from "../store";
import GoogleAnalytics from "../utils/googleAnalytics";
import { StatsProvider } from "components/contexts/statscontext.ts";

Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

const AppLayout = ({ children }) => {
  const [globalState] = useGlobal();
  const [hasJobsFailedRecently, setHasJobsFailedRecently] = useState(false);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(true);
  const { user, universe, logout } = useUserContext();
  const router = useRouter();
  const isBreakpoint = useMediaQuery(`(max-width: 1170px)`);
  const prevOpenNavKeys = useRef([]);
  const [openNavKeys, setOpenNavKeys] = useState();
  const userId = user?.id;

  useEffect(() => {
    router.events.on("routeChangeComplete", GoogleAnalytics.page);

    return () => {
      router.events.off("routeChangeComplete", GoogleAnalytics.page);
    };
  }, [router.events]);

  const handleCollapseSidebar = (isCollapsed) => {
    if (isCollapsed) {
      prevOpenNavKeys.current = [...(openNavKeys || [])];
      setOpenNavKeys([]);
    } else {
      setOpenNavKeys(prevOpenNavKeys.current);
    }
    setSidebarCollapsed(isCollapsed);
  };

  useEffect(() => {
    Router.events.on("routeChangeComplete", () => {
      if (isBreakpoint) {
        handleCollapseSidebar(true);
      }
    });
    Router.events.on("routeChangeError", () => {
      setSidebarCollapsed(true);
    });
  }, [isBreakpoint]);

  const getJobsStatus = () => {
    Services.getJobsLastDayStatus().then((res) => {
      if (!res.err) {
        setHasJobsFailedRecently(res.has_failed);
      }
    });
  };

  useEffect(() => {
    let interval;

    if (user && user.is_admin) {
      getJobsStatus();
      interval = setInterval(getJobsStatus, 1000 * 60 * 2);
    }

    return () => clearInterval(interval);
  }, [userId]);

  useEffect(() => {
    prevOpenNavKeys.current = getCurrentMenu(router.pathname, universe);
  }, [router.pathname, universe]);

  const { publicRuntimeConfig } = getConfig();
  const isProd = publicRuntimeConfig.API_HOST === publicRuntimeConfig.PROD_API_HOST;
  const isPublicPage = typeof window !== "undefined" && window.location.pathname.includes("market-pulse/public");

  const renderLogoOutButton = () => <img className={classnames({ collapsed: sidebarCollapsed })} onClick={logout} />;

  return (
    <Spin spinning={globalState.showGlobalSpinner} size="large">
      <div className={classnames("app-layout-container", { "is-sidebar-collapsed": sidebarCollapsed })}>
        {isBreakpoint ? (
          <NavHeaderMobile collapseMenu={handleCollapseSidebar} collapsed={sidebarCollapsed} className="mobile-menu" />
        ) : null}
        <aside className={"main-nav-layout"}>
          <div className={"main-nav-content"}>
            <Link href="/dashboard">
              <a className={classnames("logo-btn", { devmode: !isProd, fullsize: !sidebarCollapsed })} />
            </Link>
            <Header
              isMobile={isBreakpoint}
              hasJobsFailedRecently={hasJobsFailedRecently}
              isCollapsed={sidebarCollapsed}
              openKeys={openNavKeys}
              setOpenKeys={setOpenNavKeys}
            />
          </div>
          {!sidebarCollapsed && <div className="actions-separator"></div>}
          <div className="logout">
            {sidebarCollapsed ? (
              <Tooltip title="Log Out" placement="right" color="black">
                {renderLogoOutButton()}
              </Tooltip>
            ) : (
              <>
                <span>
                  {renderLogoOutButton()}
                  <span className="logout-text">Log Out</span>
                </span>
                <Link href="https://marketmovers.sportscardinvestor.com/dashboard?sci_fallback_choice_is_mm1=false">
                  <a className="mmx-link">Return to MMX</a>
                </Link>
              </>
            )}
          </div>
          <button
            type="button"
            className={classnames("open-close-btn", { active: sidebarCollapsed })}
            onClick={() => {
              handleCollapseSidebar(!sidebarCollapsed);
            }}
          />
        </aside>
        <section className="main-content-layout">
          {!isBreakpoint && !isPublicPage ? <DesktopTopMenu isProd={isProd} /> : <div />}
          <div
            className="main-content-container"
            onClick={() => {
              if (!sidebarCollapsed && isBreakpoint) {
                handleCollapseSidebar(true);
              }
            }}
          >
            {children}
          </div>
        </section>
      </div>
    </Spin>
  );
};

class MyApp extends App {
  render() {
    const { Component, pageProps } = this.props;
    return (
      <>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
          <link rel="manifest" href="/manifest.json" />
        </Head>
        <UserProvider>
          <ModalProvider>
            <SearchOverlayProvider>
              <StatsProvider>
                <AppLayout>
                  <BannerWrapper>
                    <Component {...pageProps} />
                  </BannerWrapper>
                </AppLayout>
              </StatsProvider>
            </SearchOverlayProvider>
          </ModalProvider>
        </UserProvider>
      </>
    );
  }
}

export default MyApp;
