import { SAVED_SEARCH_TYPES } from "../globals";
import { getAccessors } from "../utils/savedSearch";

const DEFAULT_CATEGORY = "Saved Searches";
const DEFAULT_ID = DEFAULT_CATEGORY.replace(/ /g, "_");

const getInitialSavedSearchState = (type) => {
  const { accessor, countAccessor } = getAccessors(type);
  return {
    [accessor]: {
      searches: {},
      categories: {
        [DEFAULT_ID]: {
          id: DEFAULT_ID,
          title: DEFAULT_CATEGORY,
          itemIds: [],
        },
      },
      categoryOrder: [DEFAULT_ID],
    },
    [countAccessor]: 0,
  };
};

const initialSavedSearchState = {
  ...getInitialSavedSearchState(SAVED_SEARCH_TYPES.PLAYER_CHARTS),
  ...getInitialSavedSearchState(SAVED_SEARCH_TYPES.SEALED_WAX_CHARTS),
  ...getInitialSavedSearchState(SAVED_SEARCH_TYPES.SET_AND_YEAR),
  ...getInitialSavedSearchState(SAVED_SEARCH_TYPES.SALES_LOOKUP),
  ...getInitialSavedSearchState(SAVED_SEARCH_TYPES.SEALED_WAX),
  ...getInitialSavedSearchState(SAVED_SEARCH_TYPES.GRADE_RATIOS),
  ...getInitialSavedSearchState(SAVED_SEARCH_TYPES.VARIATION_RATIOS),
  ...getInitialSavedSearchState(SAVED_SEARCH_TYPES.PLAYER_RATIOS),
};

export { initialSavedSearchState };
