import * as Sentry from "@sentry/nextjs";
import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

Sentry.init({
  dsn: publicRuntimeConfig.SENTRY_DSN,
  environment: publicRuntimeConfig.SENTRY_ENVIRONMENT,
  tracesSampleRate: 0.1,
  ignoreErrors: [
    "ResizeObserver loop limit exceeded",
    "AbortError: The user aborted a request.",
    "ResizeObserver loop completed with undelivered notifications.",
    "NetworkError when attempting to fetch resource.",
    "Failed to fetch",
  ],
});
