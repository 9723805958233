import React, { useEffect, useState } from "react";
import Router from "next/router";
import moment from "moment";
import { isMobile } from "react-device-detect";

import Services from "../../services";
import Toaster from "../../services/toaster";
import useLocalStorage from "../hooks/useLocalStorage";

const BannerWrapper = (props) => {
  const [banner, setBanner] = useState(null);
  const [lsState, setLsState] = useLocalStorage("info-banners", {});

  const fetchBannerInfo = async (url) => {
    const data = await Services.checkInfoBanner(url);
    if (data && !data.error) {
      setBanner(data.banner);
    }
  };

  useEffect(() => {
    if (isMobile && banner) {
      const url = window.location.href;
      const state = lsState[url];
      const parsedDate = moment.unix(state);

      if (!state || (state && parsedDate.isValid() && moment().diff(parsedDate, "hour") >= 1)) {
        Toaster.WarningNotification(banner.text);
        setLsState({ ...lsState, [url]: moment().unix() });
      }
    }
  }, [banner]);

  useEffect(() => {
    Router.events.on("routeChangeStart", (url) => {
      fetchBannerInfo(url);
    });
  }, []);

  return banner && !isMobile ? (
    <div>
      <div style={{ backgroundColor: "#ba2800", color: "white", padding: "10px 30px", textAlign: "center" }}>
        {banner.text}
      </div>
      <div>{props.children}</div>
    </div>
  ) : (
    props.children
  );
};

export default BannerWrapper;
