import { SAVED_SEARCH_TYPES } from "../globals";
import Services from "../services";

export const getAccessors = (type) => {
  let accessor, countAccessor;
  switch (type) {
    case SAVED_SEARCH_TYPES.PLAYER_CHARTS:
      accessor = "savedPlayerCharts";
      countAccessor = "savedPlayerChartsCount";
      break;
    case SAVED_SEARCH_TYPES.SEALED_WAX_CHARTS:
      accessor = "savedSealedWaxCharts";
      countAccessor = "savedSealedWaxChartsCount";
      break;
    case SAVED_SEARCH_TYPES.SET_AND_YEAR:
      accessor = "savedSetAndYear";
      countAccessor = "savedSetAndYearCount";
      break;
    case SAVED_SEARCH_TYPES.SALES_LOOKUP:
      accessor = "savedSalesLookup";
      countAccessor = "savedSalesLookupCount";
      break;
    case SAVED_SEARCH_TYPES.SEALED_WAX:
      accessor = "savedSealedWax";
      countAccessor = "savedSealedWaxCount";
      break;
    case SAVED_SEARCH_TYPES.GRADE_RATIOS:
      accessor = "savedGradeRatios";
      countAccessor = "savedGradeRatiosCount";
      break;
    case SAVED_SEARCH_TYPES.VARIATION_RATIOS:
      accessor = "savedVariationRatios";
      countAccessor = "savedVariationRatiosCount";
      break;
    case SAVED_SEARCH_TYPES.PLAYER_RATIOS:
      accessor = "savedPlayerRatios";
      countAccessor = "savedPlayerRatiosCount";
      break;
  }
  return { accessor, countAccessor };
};

export const getPath = (type) => {
  switch (type) {
    case SAVED_SEARCH_TYPES.SALES_LOOKUP:
      return "livesearch";
    case SAVED_SEARCH_TYPES.SEALED_WAX:
      return "sealedwaxsearch";
    case SAVED_SEARCH_TYPES.PLAYER_CHARTS:
      return "playercharts";
    case SAVED_SEARCH_TYPES.SEALED_WAX_CHARTS:
      return "wax-charts";
    case SAVED_SEARCH_TYPES.SET_AND_YEAR:
      return "setyearcharts";
    case SAVED_SEARCH_TYPES.GRADE_RATIOS:
      return "intelligence-reports/grade-ratios";
    case SAVED_SEARCH_TYPES.VARIATION_RATIOS:
      return "intelligence-reports/variation-ratios";
    case SAVED_SEARCH_TYPES.PLAYER_RATIOS:
      return "intelligence-reports/player-ratios";
  }
};

export const genHref = (type, item) => {
  const val = {
    [SAVED_SEARCH_TYPES.SALES_LOOKUP]: item.search,
    [SAVED_SEARCH_TYPES.SEALED_WAX]: item.search,
    [SAVED_SEARCH_TYPES.SEALED_WAX_CHARTS]: item.query,
    [SAVED_SEARCH_TYPES.PLAYER_CHARTS]: item.query,
    [SAVED_SEARCH_TYPES.SET_AND_YEAR]: item.query,
    [SAVED_SEARCH_TYPES.GRADE_RATIOS]: item.query,
    [SAVED_SEARCH_TYPES.VARIATION_RATIOS]: item.query,
    [SAVED_SEARCH_TYPES.PLAYER_RATIOS]: item.query,
  }[type];

  return `/${getPath(type)}${val}${type === SAVED_SEARCH_TYPES.SALES_LOOKUP ? "#savedSearchForm" : ""}`;
};

export const parseTypeTitle = (type) => {
  switch (type) {
    case SAVED_SEARCH_TYPES.PLAYER_CHARTS:
      return "Popular Card Charts";
    case SAVED_SEARCH_TYPES.SEALED_WAX_CHARTS:
      return "Popular Wax Charts";
    case SAVED_SEARCH_TYPES.SALES_LOOKUP:
      return "Chart Other Cards";
    case SAVED_SEARCH_TYPES.SET_AND_YEAR:
      return "Set & Year";
    case SAVED_SEARCH_TYPES.SEALED_WAX:
      return "Chart Other Wax";
    case SAVED_SEARCH_TYPES.GRADE_RATIOS:
      return "Grade Ratios";
    case SAVED_SEARCH_TYPES.VARIATION_RATIOS:
      return "Variation Ratios";
    case SAVED_SEARCH_TYPES.PLAYER_RATIOS:
      return "Player Ratios";
  }
};

export const getAlertLink = (type) => {
  switch (type) {
    case SAVED_SEARCH_TYPES.PLAYER_CHARTS:
      return "/playercharts";
    case SAVED_SEARCH_TYPES.SEALED_WAX_CHARTS:
      return "/wax-charts";
    case SAVED_SEARCH_TYPES.SALES_LOOKUP:
      return "/livesearch";
    case SAVED_SEARCH_TYPES.SET_AND_YEAR:
      return "/setyearcharts";
    case SAVED_SEARCH_TYPES.SEALED_WAX:
      return "/sealedwaxsearch";
    case SAVED_SEARCH_TYPES.GRADE_RATIOS:
      return "/intelligence-reports/grade-ratios";
    case SAVED_SEARCH_TYPES.VARIATION_RATIOS:
      return "/intelligence-reports/variation-ratios";
    case SAVED_SEARCH_TYPES.PLAYER_RATIOS:
      return "/intelligence-reports/player-ratios";
  }
};

export const saveOrderUpdates = async (list, source, destination, setIsSearchRunning) => {
  let items = [];
  // Start at the source index
  items = [].concat(
    items,
    list.categories[source.droppableId].itemIds.slice(source.index).map((itemId) => {
      const { id, order, category, title } = list.items[itemId];
      return { id, order, category, title };
    }),
  );
  // Add the destination as well
  if (source.droppableId !== destination.droppableId) {
    items = [].concat(
      items,
      list.categories[destination.droppableId].itemIds.slice(destination.index).map((itemId) => {
        const { id, order, category, title } = list.items[itemId];
        return { id, order, category, title };
      }),
    );
  }

  await Services.updateManySavedSearches(items);
  setIsSearchRunning(false);
};
