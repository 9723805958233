import React, { useState } from "react";
import { useRouter, withRouter } from "next/router";
import { DownOutlined, MenuOutlined, UpOutlined } from "@ant-design/icons";
import { Input, Layout } from "antd";
import { useUserContext } from "../contexts/usercontext";
import { useSearchOverlayContext } from "../contexts/searchOverlayContext";
import SearchOverlay from "../SearchOverlay";

const { Header } = Layout;

const NavHeaderMobile = ({ collapseMenu, collapsed }) => {
  const { setUniverse, universe, UNIVERSE_OPTIONS, isLoggedIn } = useUserContext();
  const { setShowOverlay, showOverlay, searchValue, setSearchValue } = useSearchOverlayContext();
  const router = useRouter();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleSelectOption = (option) => {
    setUniverse(option);
    setIsDropdownOpen(false);
    router.push("/");
  };

  const handleSelectQuickSearch = () => {
    if (!showOverlay) {
      setShowOverlay(true);
    }
    collapseMenu(true);
  };

  const toggleUniverseDropdown = () => {
    if (!isDropdownOpen) collapseMenu(true);
    setIsDropdownOpen(!isDropdownOpen);
  };

  const selectedItem = UNIVERSE_OPTIONS.find((item) => item.key === universe);
  return (
    <Header
      className="mobile-menu"
      style={{
        backgroundColor: "#004CFE",
        height: collapsed ? 65 : 130,
        top: 0,
        position: "sticky",
        width: "100%",
        zIndex: "1000",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex" }}>
          {isLoggedIn && (
            <div style={{ lineHeight: "64px", display: "flex" }}>
              <button className="mobile-menu-btn" onClick={() => collapseMenu(!collapsed)}>
                <MenuOutlined />
              </button>
            </div>
          )}
          {collapsed ? (
            <div>
              <Input
                className={"sci-overlay-search mobile-header-quick-search-trigger"}
                placeholder="e.g. Ja Morant"
                onFocus={handleSelectQuickSearch}
                value={searchValue}
                onChange={(ev) => setSearchValue(ev.target.value)}
                prefix={<img src="/search.svg" />}
              />
            </div>
          ) : (
            <a href="/dashboard">
              <img className={"logo"} style={{ width: 120 }} src="/mm-logo-white.svg" />
            </a>
          )}
        </div>
        <div>
          <div onClick={toggleUniverseDropdown} className="dropdown-main-container">
            <b style={{ color: selectedItem.color }} className="selected-option">
              {selectedItem.value}
            </b>
            <div>
              {isDropdownOpen ? <UpOutlined style={{ color: "white" }} /> : <DownOutlined style={{ color: "white" }} />}
            </div>
          </div>
          {isDropdownOpen ? (
            <div className="dropdown-container">
              {UNIVERSE_OPTIONS.map(({ key, value, color }) => (
                <div
                  className="option"
                  key={key}
                  onClick={() => handleSelectOption(key)}
                  style={{ backgroundColor: universe === key ? "rgb(0, 53, 176)" : "#004CFE" }}
                >
                  <b style={{ color }}>{value}</b>
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div>
      {!collapsed ? (
        <div className="search-box-container">
          <Input
            className={"sci-overlay-search header-search-trigger"}
            placeholder="e.g. Luka Doncic Prizm"
            onFocus={handleSelectQuickSearch}
            value={searchValue}
            onChange={(ev) => setSearchValue(ev.target.value)}
            prefix={<img src="/search.svg" />}
          />
        </div>
      ) : null}
      <SearchOverlay />
    </Header>
  );
};
export default withRouter(NavHeaderMobile);
