export const UPGRADE_TIER_MODAL_TYPES = {
  CARD_RATIOS: "CARD_RATIOS",
};

export const UPGRADE_TIER_MODAL_OBJECTS = {
  CARD_RATIOS: {
    title: "Card Ratios",
    images: [
      {
        title: "Card Ratios",
        description:
          "See the price multiplier ratio between grades (i.e. PSA 10 to PSA 9) or variations (i.e. Base to Silver) for any card to see investing opportunities in the marketplace.",
        src: "https://www.sportscardinvestor.com/wp-content/uploads/2020/07/Card-Ratios-News.png",
        alt: "card-ratios",
      },
    ],
  },
};
