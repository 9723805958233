import get from "lodash/get";

import Toaster from "services/toaster";

export const MEMBERSHIP_TIER_STARTER = "STARTER";
export const MEMBERSHIP_TIER_PREMIUM = "PREMIUM";
export const MEMBERSHIP_TIER_ULTIMATE = "UNLIMITED";

export const MembershipTiers = {
  [MEMBERSHIP_TIER_STARTER]: MEMBERSHIP_TIER_STARTER,
  [MEMBERSHIP_TIER_PREMIUM]: MEMBERSHIP_TIER_PREMIUM,
  [MEMBERSHIP_TIER_ULTIMATE]: MEMBERSHIP_TIER_ULTIMATE,
};

export const membershipTierValues = Object.freeze(Object.values(MembershipTiers));

export const MEMBERSHIP_TIER_LIMIT_CATEGORY_ALERTS = "ALERTS";
export const MEMBERSHIP_TIER_LIMIT_CATEGORY_COLLECTION = "COLLECTION";
export const MEMBERSHIP_TIER_LIMIT_CATEGORY_DATE_RANGES = "DATE_RANGES";
export const MEMBERSHIP_TIER_LIMIT_CATEGORY_FAVORITES = "FAVORITES";

export const MembershipTierLimits = {
  [MEMBERSHIP_TIER_LIMIT_CATEGORY_ALERTS]: {
    [MEMBERSHIP_TIER_STARTER]: 5,
    [MEMBERSHIP_TIER_PREMIUM]: 20,
  },
  [MEMBERSHIP_TIER_LIMIT_CATEGORY_COLLECTION]: {
    [MEMBERSHIP_TIER_STARTER]: 10,
    [MEMBERSHIP_TIER_PREMIUM]: 50,
  },
  [MEMBERSHIP_TIER_LIMIT_CATEGORY_DATE_RANGES]: {
    [MEMBERSHIP_TIER_STARTER]: 30,
    [MEMBERSHIP_TIER_PREMIUM]: 90,
  },
  [MEMBERSHIP_TIER_LIMIT_CATEGORY_FAVORITES]: {
    [MEMBERSHIP_TIER_STARTER]: 10,
  },
};

export function membershipTierExists(nameOfMembershipTier = "") {
  return nameOfMembershipTier.toUpperCase() in MembershipTiers;
}

export function areMembershipTiersEqual(a = "", b = "") {
  const equal = a.toUpperCase() == b.toUpperCase();
  return equal;
}

export const MembershipTierLimitCategories = {
  [MEMBERSHIP_TIER_LIMIT_CATEGORY_ALERTS]: MEMBERSHIP_TIER_LIMIT_CATEGORY_ALERTS,
  [MEMBERSHIP_TIER_LIMIT_CATEGORY_COLLECTION]: MEMBERSHIP_TIER_LIMIT_CATEGORY_COLLECTION,
  [MEMBERSHIP_TIER_LIMIT_CATEGORY_DATE_RANGES]: MEMBERSHIP_TIER_LIMIT_CATEGORY_DATE_RANGES,
  [MEMBERSHIP_TIER_LIMIT_CATEGORY_FAVORITES]: MEMBERSHIP_TIER_LIMIT_CATEGORY_FAVORITES,
};

export const MEMBERSHIP_TIER_LIMIT_NONE = Number.POSITIVE_INFINITY;

export function getLimitForMembershipTierAndCategory(nameOfMembershipTier = "", limitCategory = "") {
  return get(
    MembershipTierLimits,
    `${limitCategory}.${nameOfMembershipTier.toUpperCase()}`,
    MEMBERSHIP_TIER_LIMIT_NONE,
  );
}

export function getTabTextForMembershipTier(nameOfMembershipTier = "") {
  const dateRangeLimit = getLimitForMembershipTierAndCategory(
    nameOfMembershipTier,
    MembershipTierLimitCategories.DATE_RANGES,
  );
  return `Your plan includes data from the last ${dateRangeLimit} days only. Upgrade your plan to access additional time periods.`;
}

export function isUserOfMembershipTiers(user, namesOfMembershipTiers = []) {
  return namesOfMembershipTiers.some((t) => isUserOfMembershipTier(user, t));
}

// TODO: constant-ize `'membership_tier'` property name here or in the model

// TODO: memoize user function results off `user.id`

// TODO: either add User type or do object validation

export function isUserOfMembershipTier(user, nameOfMembershipTier) {
  if (!membershipTierExists(nameOfMembershipTier)) {
    console.warn("!isUserOfMembershipTier", user, nameOfMembershipTier);
    return false;
  }

  return areMembershipTiersEqual(user.membership_tier, nameOfMembershipTier);
}

export function isUserAtLeast(user, nameOfMembershipTier) {
  if (!membershipTierExists(nameOfMembershipTier)) return false;

  const userMembershipTier = (user.membership_tier || "").toUpperCase();
  if (!userMembershipTier) {
    console.error(`"membership_tier" empty or not present for user ${JSON.stringify(user)}`);
  }

  const indexOfUserMembershipTier = membershipTierValues.indexOf(userMembershipTier.toUpperCase());
  const indexOfComparisonMembershipTier = membershipTierValues.indexOf(nameOfMembershipTier.toUpperCase());

  console.log("isUserAtLeast", indexOfUserMembershipTier, indexOfComparisonMembershipTier, user, nameOfMembershipTier);

  return indexOfUserMembershipTier >= indexOfComparisonMembershipTier;
}

export const upgradeURL = "https://www.sportscardinvestor.com/account/?action=subscriptions";

export function takeUserToUpgradePath() {
  window.open(upgradeURL);
}

// TODO: move somewhere UI-related
export function showMembershipTierLimitMessage(message) {
  Toaster.ErrorNotification(message, null, () => {
    takeUserToUpgradePath();
  });
}
