import React, { useEffect, useRef, useState } from "react";
import { CloseOutlined, DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Divider, Input, List, Spin, Typography } from "antd";
import { useRouter } from "next/router";
import moment from "moment";
import { useSearchOverlayContext } from "./contexts/searchOverlayContext";
import Services from "../services";
import Helpers from "../services/helpers";
import { getHighlightedText } from "../utils/cardList";
import Toaster from "../services/toaster";
import { useUserContext } from "./contexts/usercontext";

const MAX_SELECTED_ENTITIES = 7;
const CHECKBOX_WIDTH_PERCENT = 15;

const parseDescriptions = (data) => {
  return data.map((item) => {
    let description = `${item.set_year} ${item.set_name}`;

    if (item.card_number) {
      description += ` #${item.card_number}`;
    }

    description += ` ${item.variation_name}`;

    if (item.print_run) {
      description += ` ${item.print_run}`;
    }
    if (item.specific_qualifier) {
      description += ` (${item.specific_qualifier})`;
    }

    return {
      ...item,
      description,
    };
  });
};

const SearchOverlay = () => {
  const inputRef = useRef();
  const isFirstRender = useRef(true);
  const abortControllerRef = useRef();
  const { showOverlay, setShowOverlay, isCollapsed, setSearchValue, searchValue } = useSearchOverlayContext();
  const { universe = "card", user } = useUserContext();
  const [entities, setEntities] = useState([]);
  const [selectedEntities, setSelectedEntities] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [initLoading, setInitLoading] = useState(true);
  const [count, setCount] = useState(0);
  const router = useRouter();

  const userExists = user && user.id;

  useEffect(() => {
    abortControllerRef.current = new window.AbortController();
  }, []);

  const [
    label,
    chartHref,
    chartIdentifier,
    chartOtherHref,
    placeholder,
    searchFn,
    searchTitleExcludes,
    parseEntitiesFn,
    getMainTerm,
    getBoldTerm,
    getImageUrl,
  ] = {
    card: [
      "Cards",
      "/playercharts",
      "cid",
      "/livesearch",
      "e.g. Luka Doncic Prizm",
      Services.searchOpenIndex,
      ["sport_name", "player_name", "grade_name"],
      (res) => parseDescriptions(res.data),
      (item) => item.player_name,
      (item) => item.grade_name,
      (item) => (item.image_url ? `${item.image_url}-S` : item.admin_image_url || "/no-image.png"),
    ],
    wax: [
      "Wax",
      "/wax-charts",
      "swid",
      "/sealedwaxsearch",
      "e.g. 2003 Topps Chrome Basketball Hobby Box",
      Services.getPopularSealedWaxes,
      ["set_name", "set_year", "sport_name"],
      (res) => res.data,
      (item) => Helpers.generateSetName(item.card_set),
      () => "",
      (item) => item.image_url || "/no-image.png",
    ],
  }[universe];

  const handleSearch = async (term, signal) => {
    setSearchLoading(true);
    const res = await searchFn(
      term || searchValue,
      {
        limit: 50,
        titleExcludes: searchTitleExcludes,
      },
      signal,
    );

    if (isFirstRender.current) {
      setInitLoading(false);
      isFirstRender.current = false;
    }

    if (res && !res.error) {
      setEntities(parseEntitiesFn(res));
      setCount(res.total);
    }

    setSearchLoading(false);
  };

  const handleLoadMore = async () => {
    setSearchLoading(true);

    // grade_name is filtered out because it comes separated for bold text
    const res = await searchFn(searchValue, {
      titleExcludes: searchTitleExcludes,
      limit: 50,
      offset: entities.length,
    });

    if (res && !res.error) {
      setEntities((prev) => prev.concat(parseEntitiesFn(res)));
    }

    setSearchLoading(false);
  };

  const handleSelect = (ids) => {
    setShowOverlay(false);
    if (ids !== -1) {
      if (window.location.href.includes(chartHref.replace("/", ""))) {
        handleSearch("");
      }
      router.push(`${chartHref}?${chartIdentifier}=${ids.join(",")}`);
    } else {
      router.push(chartOtherHref);
    }
  };

  const handleSetSearchValue = (ev) => {
    const value = ev.target.value;
    setSearchValue(value);
  };

  const handleCheckbox = (e, entity) => {
    e.stopPropagation();

    const to = [...selectedEntities];
    const index = to.findIndex(({ id }) => entity.id === id);

    if (index === -1) to.push(entity);
    else to.splice(index, 1);

    if (to.length > MAX_SELECTED_ENTITIES) {
      Toaster.WarningNotification(`Maximum number of ${label.toLowerCase()} selected.`);
      return;
    }

    setSelectedEntities(to);
  };

  useEffect(() => {
    if (!showOverlay && searchValue) {
      setShowOverlay(true);
    }

    abortControllerRef.current.abort();
    abortControllerRef.current = new window.AbortController();
    const delayDebounceFn = setTimeout(() => userExists && handleSearch(null, abortControllerRef.current.signal), 400);
    return () => clearTimeout(delayDebounceFn);
  }, [searchValue, universe, userExists]);

  useEffect(() => {
    if (showOverlay) {
      setTimeout(inputRef.current?.focus, 10);
    }
  }, [showOverlay, inputRef.current]);

  useEffect(() => {
    const handleEscape = (ev) => ev.keyCode === 27 && setShowOverlay(false);
    document.addEventListener("keydown", handleEscape, false);

    return () => document.removeEventListener("keydown", handleEscape, false);
  }, []);

  if (!showOverlay) {
    return null;
  }

  const renderActionButtons = () => (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <div style={{ margin: "16px 8px" }}>
          <Button
            icon={<DeleteOutlined />}
            type="secondary"
            onClick={() => setSelectedEntities([])}
            style={{ marginRight: 8 }}
          >
            Clear
          </Button>
          <Button
            icon={<SearchOutlined />}
            type="primary"
            onClick={() => handleSelect(selectedEntities.map((c) => c.id))}
          >
            View Chart
          </Button>
        </div>
      </div>
    </>
  );

  const chartOtherCardOption = {
    id: -1,
    description: (
      <span>
        Not Here? Try&nbsp;
        <b
          style={{ color: "#0035b0" }}
          onClick={() => {
            router.push(chartOtherHref);
            setShowOverlay(false);
          }}
        >
          Chart Other {label}
        </b>
        !
      </span>
    ),
  };

  const loadMore = searchValue && !initLoading && !searchLoading && (
    <>
      <p style={{ textAlign: "center" }}>
        Showing {entities.length} of {count > 9999 ? "10000 +" : count} matching results
      </p>
      <Button onClick={handleLoadMore}>Load more</Button>
    </>
  );

  return (
    <div className={`backgroundContainer ${isCollapsed ? "collapsed" : ""}`} onClick={() => setShowOverlay(false)}>
      <div className={"centeredContainer"} onClick={(ev) => ev.stopPropagation()}>
        <div className={"header"}>
          <div style={{ width: 450, display: "inline-block" }}>
            <Input
              ref={inputRef}
              allowClear={!searchLoading}
              id="sci-overlay-search"
              placeholder={placeholder}
              onPressEnter={handleSearch}
              onChange={handleSetSearchValue}
              value={searchValue}
              prefix={<img src="/search.svg" />}
              suffix={searchLoading && <Spin size="small" />}
              autoComplete="off"
            />
          </div>
          <CloseOutlined className={"closeIcon"} onClick={() => setShowOverlay(false)} />
        </div>
        <div className={"listContainer"}>
          {selectedEntities.length > 0 && (
            <>
              <Typography.Title level={4} style={{ display: "inline-block" }}>
                Selected {label}{" "}
                <span style={{ fontSize: 14 }}>
                  ({selectedEntities.length}/{MAX_SELECTED_ENTITIES})
                </span>
              </Typography.Title>
              <List
                dataSource={selectedEntities}
                rowKey={(item) => item.id}
                renderItem={(item) => {
                  return (
                    <List.Item className="listItem" onClick={(e) => handleCheckbox(e, item)}>
                      <img src={getImageUrl(item)} className={"cardImage"} alt={item.description} />
                      <div
                        className="description"
                        style={{ width: `calc(100% - ${CHECKBOX_WIDTH_PERCENT}% - 90px)`, alignSelf: "center" }}
                      >
                        <span style={{ fontWeight: 600 }}>
                          {item.id !== -1 ? getHighlightedText(getMainTerm(item), searchValue) : null}
                        </span>
                        <br />
                        <span>
                          {getHighlightedText(item.description, searchValue)}&nbsp;
                          <span style={{ fontWeight: 600 }}>{getHighlightedText(getBoldTerm(item), searchValue)}</span>
                        </span>
                        {item.all_end_avg ? (
                          <div>
                            <span>{Helpers.formatMoney(item.all_end_avg)} </span>
                            <span style={{ fontSize: "15.5px" }}>
                              ({moment.tz(item.last_sale_date, "America/Chicago").format("MM/DD/YYYY")})
                            </span>
                          </div>
                        ) : (
                          <div>No Recorded Sales</div>
                        )}
                      </div>
                      <div className="checkbox">
                        <img src="/tick-blue.svg" />
                      </div>
                    </List.Item>
                  );
                }}
              />
              {renderActionButtons()}
            </>
          )}
          {entities.length > 0 && selectedEntities.length > 0 && <Divider />}
          {entities.length > 0 && <div className="compare-col">Compare</div>}
          <List
            dataSource={[...entities, ...(searchValue ? [chartOtherCardOption] : [])]}
            loading={searchLoading}
            rowKey={(item) => item.id}
            renderItem={(item) => {
              if (selectedEntities.some(({ id }) => id === item.id)) {
                return null;
              }

              return (
                <List.Item
                  onClick={(e) => {
                    if (item.id === -1) return null;
                    if (!selectedEntities.length) return handleSelect([item.id]);
                    return handleCheckbox(e, item);
                  }}
                  className={"listItem"}
                  style={item.id === -1 ? { borderBottom: "none", marginBottom: 20 } : {}}
                >
                  {item.id !== -1 && <img src={getImageUrl(item)} className={"cardImage"} alt={item.description} />}
                  <div
                    className="description"
                    style={{
                      width: item.id !== -1 ? `calc(100% - ${CHECKBOX_WIDTH_PERCENT}% - 90px)` : "100%",
                      alignSelf: "center",
                      ...(item.id === -1 && entities.length < count
                        ? { display: "flex", flexDirection: "column", justifyContent: "center" }
                        : {}),
                    }}
                  >
                    {item.id !== -1 ? (
                      <>
                        <span style={{ fontWeight: 600, position: "relative" }}>
                          {item.id !== -1 ? getHighlightedText(getMainTerm(item), searchValue) : null}
                          {item.rc ? (
                            <span className="rookie-card-token" style={{ top: 0, right: -40 }}>
                              RC
                            </span>
                          ) : null}
                        </span>
                        <br />
                        <span>
                          {getHighlightedText(item.description, searchValue)}&nbsp;
                          <span style={{ fontWeight: 600 }}>{getHighlightedText(getBoldTerm(item), searchValue)}</span>
                        </span>
                        {item.all_end_avg ? (
                          <div>
                            <span>{Helpers.formatMoney(item.all_end_avg)} </span>
                            <span style={{ fontSize: "15.5px" }}>
                              ({moment.tz(item.last_sale_date, "America/Chicago").format("MM/DD/YYYY")})
                            </span>
                          </div>
                        ) : (
                          <div>No Recorded Sales</div>
                        )}
                      </>
                    ) : entities.length < count ? (
                      loadMore
                    ) : (
                      <span>{item.description}</span>
                    )}
                  </div>
                  {item.id !== -1 && (
                    <div className="checkbox" onClick={(e) => handleCheckbox(e, item)}>
                      <img src="/tick-gray.svg" />
                    </div>
                  )}
                </List.Item>
              );
            }}
          />
          {selectedEntities.length > 0 && renderActionButtons()}
        </div>
      </div>
    </div>
  );
};

export default SearchOverlay;
