import { notification } from "antd";

const NOTIFICATION_DURATION_DEFAULT = 4.5;
const NOTIFICATION_ONCLICK_DEFAULT = () => {};

const openNotificationWithIcon = (
  type,
  message,
  description,
  onClick = NOTIFICATION_ONCLICK_DEFAULT,
  duration = NOTIFICATION_DURATION_DEFAULT,
) => {
  notification[type]({
    message,
    description,
    duration,
    onClick,
  });
};

const SuccessNotification = (message, description, onClick) => {
  openNotificationWithIcon("success", message, description, onClick);
};
const InfoNotification = (message, description, onClick) => {
  openNotificationWithIcon("info", message, description, onClick);
};
const WarningNotification = (message, description, onClick) => {
  openNotificationWithIcon("warning", message, description, onClick);
};
const ErrorNotification = (message, description, onClick) => {
  openNotificationWithIcon("error", message, description, onClick);
};
const CloseOnClickErrorNotification = (message, description) => {
  openNotificationWithIcon("error", message, description, undefined, 0);
};

export default {
  SuccessNotification,
  InfoNotification,
  WarningNotification,
  ErrorNotification,
  CloseOnClickErrorNotification,
};
