import React from "react";
import globalHook from "use-global-hook";
import { initialSavedSearchState } from "./store/savedSearches";
import { initialFavoritesState, favoriteActions } from "./store/favorites";
import { collectionActions } from "./store/collection";

const initialState = Object.assign(
  {},
  {
    ebayQuery: "",
    shouldUpdateManageCardList: false,
    disableAdminPanelButtons: false,
    excludeSellersFromChartResults: [],
    excludePlatformsFromChartResults: [],
    showSellerInformation: true,
    showSellerInformationLiveSearch: false,
    showBuyerInformation: true,
    excludeNoFeedback: true,
    setExcludeNoSellerFeedback: true,
    excludeLowFeedback: false,
    showGlobalSpinner: false,
    excludeBuyItNow: false,
    excludeAuctions: false,
    excludeSaleWithMakeOffer: false,
    collectionCardIds: [],
    collectionSealedWaxIds: [],
  },
  initialSavedSearchState,
  initialFavoritesState,
);

const actions = Object.assign(
  {},
  {
    setEbayQuery: (store, query) => {
      store.setState({ query });
    },
    setShouldUpdateManageCardList: (store, shouldUpdateManageCardList) => {
      store.setState({ shouldUpdateManageCardList });
    },
    setDisableAdminPanelButtons: (store, disableAdminPanelButtons) => {
      store.setState({ disableAdminPanelButtons });
    },
    setExcludeSellersFromChartResults: (store, excludeSellersFromChartResults, seller) => {
      if (excludeSellersFromChartResults.includes(seller)) {
        const nextExcludeSellersFromChartResults = excludeSellersFromChartResults.filter((excludedSeller) => {
          if (seller === "pwcc_auctions") {
            return ![seller, "PWCC"].includes(excludedSeller);
          }
          return seller !== excludedSeller;
        });
        store.setState({ excludeSellersFromChartResults: nextExcludeSellersFromChartResults });
      } else {
        const sellers = [seller];
        if (seller === "pwcc_auctions") {
          sellers.push("PWCC");
        }
        store.setState({
          excludeSellersFromChartResults: [].concat(excludeSellersFromChartResults, sellers),
        });
      }
    },
    setExcludePlatformsFromChartResults: (store, excludePlatformsFromChartResults, platform) => {
      if (excludePlatformsFromChartResults.includes(platform)) {
        store.setState({
          excludePlatformsFromChartResults: excludePlatformsFromChartResults.filter((item) => {
            return platform !== item;
          }),
        });
      } else {
        const platforms = [platform];
        store.setState({
          excludePlatformsFromChartResults: [].concat(excludePlatformsFromChartResults, platforms),
        });
      }
    },
    setShowSellerInformation: (store, showSellerInformation) => {
      store.setState({ showSellerInformation });
    },
    setShowSellerInformationLiveSearch: (store, showSellerInformationLiveSearch) => {
      store.setState({ showSellerInformationLiveSearch });
    },
    setShowBuyerInformation: (store, showBuyerInformation) => {
      store.setState({ showBuyerInformation });
    },
    setExcludeNoFeedback: (store, excludeNoFeedback) => {
      store.setState({ excludeNoFeedback });
    },
    setExcludeNoSellerFeedback: (store, excludeNoSellerFeedback) => {
      store.setState({ excludeNoSellerFeedback });
    },
    setExcludeLowFeedback: (store, excludeLowFeedback) => {
      store.setState({ excludeLowFeedback });
    },
    setShowGlobalSpinner: (store, showGlobalSpinner) => {
      store.setState({ showGlobalSpinner });
    },
    setExcludeBuyItNow: (store, excludeBuyItNow) => {
      store.setState({ excludeBuyItNow });
    },
    setExcludeAuctions: (store, excludeAuctions) => {
      store.setState({ excludeAuctions });
    },
    setExcludeSaleWithMakeOffer: (store, excludeSaleWithMakeOffer) => {
      store.setState({ excludeSaleWithMakeOffer });
    },
    setCollectionCardIds: (store, collectionCardIds) => {
      store.setState({ collectionCardIds });
    },
    setCollectionSealedWaxIds: (store, collectionSealedWaxIds) => {
      store.setState({ collectionSealedWaxIds });
    },
    setCollectionCustomCardIds: (store, collectionCustomCardIds) => {
      store.setState({ collectionCustomCardIds });
    },
  },
  collectionActions,
  favoriteActions,
);

const useGlobal = globalHook(React, initialState, actions);

export default useGlobal;
