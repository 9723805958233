import React, { useState } from "react";
import { Input } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { useSearchOverlayContext } from "../contexts/searchOverlayContext";
import { useUserContext } from "../contexts/usercontext";
import SearchOverlay from "../SearchOverlay";
import { useRouter } from "next/router";
import Link from "next/link";

const DesktopTopMenu = () => {
  const { setShowOverlay, showOverlay, searchValue, setSearchValue } = useSearchOverlayContext();
  const router = useRouter();
  const { setUniverse, universe, UNIVERSE_OPTIONS, user } = useUserContext();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleSelectOption = (option) => {
    setUniverse(option);
    setIsDropdownOpen(false);
    router.push("/dashboard");
  };

  const [href, placeholder] = {
    card: ["/playercharts", "e.g. Luka Doncic Prizm"],
    wax: [user?.is_admin ? "/wax-charts" : "/playercharts", "e.g. 2003 Topps Chrome Basketball Hobby Box"],
  }[universe];

  const selectedItem = UNIVERSE_OPTIONS.find((item) => item.key === universe);
  return (
    <div className="main-nav-container">
      <div>
        <div onClick={() => setIsDropdownOpen(!isDropdownOpen)} className="dropdown-main-container">
          <b style={{ color: selectedItem.color }} className="selected-option">
            {selectedItem.value}
          </b>
          <div>
            {isDropdownOpen ? <UpOutlined style={{ color: "white" }} /> : <DownOutlined style={{ color: "white" }} />}
          </div>
        </div>

        {isDropdownOpen ? (
          <>
            <div className={"shadowContainer"} onClick={() => setIsDropdownOpen(false)} />
            <div className="dropdown-container">
              {UNIVERSE_OPTIONS.map(({ key, value, color }) => (
                <div
                  className={`option ${key === universe ? "selected" : ""}`}
                  key={key}
                  onClick={() => handleSelectOption(key)}
                >
                  <b style={{ color }}>{value}</b>
                </div>
              ))}
            </div>
          </>
        ) : null}
      </div>
      <div className="divider" />
      <div className={"sci-overlay-search-container"}>
        <Input
          className={"sci-overlay-search"}
          placeholder={placeholder}
          onFocus={() => !showOverlay && setShowOverlay(true)}
          value={searchValue}
          onChange={(ev) => setSearchValue(ev.target.value)}
          prefix={<img src="/search.svg" />}
        />
        {searchValue && (
          <button
            type="button"
            className="clear-search-btn"
            onClick={() => {
              setSearchValue("");
              setShowOverlay(false);
            }}
          />
        )}
      </div>
      <SearchOverlay />
      <Link href={href}>
        <a className="pcc-link">Advanced Search</a>
      </Link>
      <Link href="https://marketmovers.sportscardinvestor.com/dashboard?sci_fallback_choice_is_mm1=false">
        <a className="mmx-link">Return to MMX</a>
      </Link>
    </div>
  );
};

export default DesktopTopMenu;
