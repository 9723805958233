import { Dispatch, SetStateAction, useState } from "react";
import createUseContext from "constate"; // State Context Object Creator

type Filters = {
  sportFilter?: string;
  playerFilter?: string;
  setFilter?: string;
  yearFilter?: string;
  gradeFilter?: string;
  variationFilter?: string;
  filterGradedCardsOnly?: string;
  minAvgSale?: string;
  maxAvgSale?: string;
  minSaleCount?: string;
  maxSaleCount?: string;
};

type StatsContext = {
  filters: Filters;
  setFilters: Dispatch<SetStateAction<Filters>>;
};

function useStats(): StatsContext {
  const [filters, setFilters] = useState<Filters>({});

  return {
    filters,
    setFilters,
  };
}

const [StatsProvider, useStatsContext] = createUseContext(useStats);

export { StatsProvider, useStatsContext };
