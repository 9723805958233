import { useEffect, useState } from "react";

function useLocalStorage(key, initialValue) {
  const [storedValue, setStoredValue] = useState(initialValue);
  const [hasReadValueFromLS, setHasReadValueFromLS] = useState(false);

  const setValue = (value) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    try {
      const item = window.localStorage.getItem(key);
      setValue(item ? JSON.parse(item) : initialValue);
      setHasReadValueFromLS(true);
    } catch (error) {
      console.error(error);
      setValue(initialValue);
    }
  }, []);

  return [storedValue, setValue, hasReadValueFromLS];
}

export default useLocalStorage;
