import { useState } from "react";
import createUseContext from "constate";

function useSearchOverlay() {
  const [showOverlay, setShowOverlay] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [searchValue, setSearchValue] = useState("");

  return {
    showOverlay,
    setShowOverlay,
    isCollapsed,
    setIsCollapsed,
    searchValue,
    setSearchValue,
  };
}

const [SearchOverlayProvider, useSearchOverlayContext] = createUseContext(useSearchOverlay);

export { SearchOverlayProvider, useSearchOverlayContext };
