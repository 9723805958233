const DEFAULT_CATEGORY = "Saved Favorites";
const DEFAULT_ID = DEFAULT_CATEGORY.replace(/ /g, "_");

const initialFavoritesPlayers = {
  items: {},
  categories: {
    [DEFAULT_ID]: {
      id: DEFAULT_ID,
      title: DEFAULT_CATEGORY,
      itemIds: [],
    },
  },
  categoryOrder: [DEFAULT_ID],
};

const initialFavoritesCards = {
  items: {},
  categories: {
    [DEFAULT_ID]: {
      id: DEFAULT_ID,
      title: DEFAULT_CATEGORY,
      itemIds: [],
    },
  },
  categoryOrder: [DEFAULT_ID],
};

const initialFavoritesSealedWax = {
  items: {},
  categories: {
    [DEFAULT_ID]: {
      id: DEFAULT_ID,
      title: DEFAULT_CATEGORY,
      itemIds: [],
    },
  },
  categoryOrder: [DEFAULT_ID],
};

const initialFavoritesState = {
  savedFavoritesCards: initialFavoritesCards,
  savedFavoritesSealedWax: initialFavoritesSealedWax,
  savedFavoritesPlayers: initialFavoritesPlayers,
  savedFavoritesCardsCount: 0,
  savedFavoritesPlayersCount: 0,
  shallowFavorites: [],
};

const favoriteActions = {
  setShallowFavorites: (store, shallowFavorites) => {
    store.setState({ shallowFavorites });
  },
};

export { initialFavoritesState, favoriteActions };
