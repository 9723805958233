import { useEffect, useState } from "react";
import getConfig from "next/config";
import moment from "moment";
import ls from "local-storage";
import createUseContext from "constate";
import * as Sentry from "@sentry/nextjs";
import Services from "../../services";
import useGlobal from "../../store";

const UNIVERSE_OPTIONS = [
  {
    key: "card",
    value: "Sports Cards",
    color: "orange",
  },
  {
    key: "wax",
    value: "Sealed Wax",
    color: "lightblue",
  },
];

function useUser() {
  let existingToken = Services.checkTokenSync();
  let existingExpiresAt = Services.getExpiresAt();
  if (existingExpiresAt) {
    const expiredMoment = moment(existingExpiresAt);
    const nowMoment = new moment();
    if (expiredMoment.isBefore(nowMoment)) {
      existingToken = null;
      existingExpiresAt = null;
    }
  }

  const [user, setUser] = useState(Services.getUser() || {});
  const [universe, setUniverse] = useState("card");
  const [didMountUniverse, setDidMountUniverse] = useState(false);
  const [, globalActions] = useGlobal();

  const handleSetUniverse = (universe) => {
    setUniverse(universe);
    if (typeof window !== "undefined") {
      window.localStorage.setItem("universe", universe);
    }
  };

  useEffect(() => {
    const savedUniverse = window.localStorage.getItem("universe");
    if (savedUniverse) {
      setUniverse(savedUniverse);
    }

    setDidMountUniverse(true);
  }, []);

  useEffect(() => {
    if (existingToken) {
      globalActions.setShowGlobalSpinner(true);
      Services.getLoggedInUser()
        .then((data) => {
          setUser(data);
          Services.setUser(data);
        })
        .catch((error) => {
          setUser(null);
          Sentry.captureException(error);
        })
        .finally(() => globalActions.setShowGlobalSpinner(false));
    } else {
      setUser(null);
    }
  }, []);

  const [isLoggedIn, setIsLoggedIn] = useState(existingToken);

  const login = (loginPayload) => {
    setIsLoggedIn(true);
    Services.setToken(loginPayload.token);
    setUser(loginPayload.user);
    Services.setUser(loginPayload.user);
    const expires = moment().add(loginPayload.token_expires_in, "s");
    Services.setExpiresAt(expires);
    Services.setDidReLogin(new moment());
  };
  const logout = () => {
    const { publicRuntimeConfig } = getConfig();
    ls.clear();
    window.location.replace(`${publicRuntimeConfig.WP_HOST}/wp-login.php?loggedout=true&redirect_to=sci&wp_lang=en_US`);
  };

  const isTokenExpired = () => {
    const did_relog = Services.getDidReLogin();
    if (!did_relog) return true;
    const expiredMoment = new moment(Services.getExpiresAt());
    const nowMoment = new moment();
    // If the expiration is before now, it's expired
    return expiredMoment.isBefore(nowMoment);
  };

  return {
    user,
    login,
    logout,
    isTokenExpired,
    universe,
    isLoggedIn,
    setUniverse: handleSetUniverse,
    didMountUniverse,
    UNIVERSE_OPTIONS,
  };
}

const [UserProvider, useUserContext] = createUseContext(useUser);

export { UserProvider, useUserContext };
