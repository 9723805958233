import React, { useEffect } from "react";
import Link from "next/link";
import { withRouter } from "next/router";
import Icon, { LockOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import { useUserContext } from "../contexts/usercontext";
import { customHeaderIcons, getDesktopHeaderItems } from "./assets";
import { useModalContext } from "../contexts/modalcontext";
import { useSearchOverlayContext } from "../contexts/searchOverlayContext";
import { useStatsContext } from "../contexts/statscontext.ts";

const { SubMenu } = Menu;

const NavHeader = ({ isCollapsed, hasJobsFailedRecently, openKeys, setOpenKeys }) => {
  const { isLoggedIn, user = "", universe } = useUserContext();
  const { setIsUpgradeTierModalVisible } = useModalContext();
  const { filters } = useStatsContext();
  const { setIsCollapsed } = useSearchOverlayContext();

  useEffect(() => {
    setIsCollapsed(isCollapsed);
  }, [isCollapsed]);

  if (!isLoggedIn) {
    return null;
  }

  const _renderMenuItem = (headerItem) => {
    const { key, iconType, customIcon, title, subItems, showMiddleware, lockMiddleware } = headerItem;

    if (showMiddleware && !showMiddleware(user || {})) return null;

    const isLocked = lockMiddleware && lockMiddleware(user || {});
    const additonalProps = {};

    if (isLocked) {
      additonalProps.onTitleClick = () => {
        setIsUpgradeTierModalVisible(true);
      };
    }

    return subItems ? (
      <SubMenu
        key={key}
        title={
          <>
            {customIcon && iconType && (
              <Icon viewBox={customHeaderIcons[iconType].viewBox} component={customHeaderIcons[iconType].icon} />
            )}
            {!customIcon && iconType && <Icon component={iconType} />}
            <span>{_renderItemTitle(title)}</span>
            {isLocked && <LockOutlined />}
          </>
        }
        {...additonalProps}
      >
        {!isLocked && subItems.map((subItem) => _renderSingleMenuItem(subItem)).filter((i) => i)}
      </SubMenu>
    ) : (
      _renderSingleMenuItem(headerItem)
    );
  };

  const _renderSingleMenuItem = (item) => {
    const { href, target = null, title, iconType, customIcon, showMiddleware, lockMiddleware, render } = item;

    if (showMiddleware && !showMiddleware(user || {})) return null;

    const isLocked = lockMiddleware && lockMiddleware(user || {});
    const additonalProps = {};

    if (isLocked) {
      additonalProps.onClick = (e) => {
        e.preventDefault();
        setIsUpgradeTierModalVisible(true);
      };
    }

    return (
      <Menu.Item key={href}>
        <Link href={href}>
          <a {...additonalProps} target={target}>
            {customIcon && iconType && (
              <Icon viewBox={customHeaderIcons[iconType].viewBox} component={customHeaderIcons[iconType].icon} />
            )}
            {!customIcon && iconType && <Icon component={iconType} />}
            {render ? render({ hasJobsFailedRecently }) : <span>{_renderItemTitle(title)}</span>}
            {isLocked && <LockOutlined />}
          </a>
        </Link>
      </Menu.Item>
    );
  };

  const _renderItemTitle = (title) => {
    if (typeof title === "string") return title;

    return title(user);
  };

  return (
    <Menu
      theme="light"
      mode="inline"
      key={0}
      style={{ lineHeight: "64px", marginLeft: "auto" }}
      selectedKeys={[location.pathname]}
      openKeys={openKeys}
      onOpenChange={setOpenKeys}
      inlineCollapsed={isCollapsed}
    >
      {getDesktopHeaderItems(universe, { filters }).map((item) => _renderMenuItem(item))}
    </Menu>
  );
};

export default withRouter(NavHeader);
