import { useEffect, useState } from "react";

const useMediaQuery = (queryInput) => {
  const [targetReached, setTargetReached] = useState(false);

  const updateTarget = (e) => {
    if (e.matches) {
      setTargetReached(true);
    } else {
      setTargetReached(false);
    }
  };

  useEffect(() => {
    const media = window.matchMedia(queryInput);

    if (media.addEventListener) {
      media.addEventListener("change", updateTarget);
    } else if (media.addListener) {
      // deprecated, for old browsers
      media.addListener(updateTarget);
    }

    if (media.matches) {
      setTargetReached(true);
    }

    return () => {
      if (media.removeEventListener) {
        media.removeEventListener("change", updateTarget);
      } else if (media.removeListener) {
        media.removeListener(updateTarget);
      }
    };
  }, []);

  return targetReached;
};

export default useMediaQuery;
