import { SAVED_SEARCH_TYPES, FAVORITE_TYPES, COLLECTION_TYPES } from "../globals";

const generateCollectionFromResponse = ({ items, collectionType }) => {
  const DEFAULT_ID = getDefaultId(collectionType);
  const DEFAULT_CATEGORY = unSnake(DEFAULT_ID);
  const collection = {
    items: {},
    categories: {
      [DEFAULT_ID]: {
        id: DEFAULT_ID,
        title: DEFAULT_CATEGORY,
        itemIds: [],
      },
    },
    categoryOrder: [DEFAULT_ID],
  };
  items.map((item, order) => {
    addInitialItemsToCollection(
      collection,
      item,
      item.category ? item.category : DEFAULT_ID,
      item.order ? item.order : order,
    );
  });
  return collection;
};

const addInitialItemsToCollection = (collection, item, category, order) => {
  if (!item.category) {
    item.category = category;
  }
  if (!item.order) {
    item.order = order;
  }
  // Add to the hash map of items
  collection.items[item.id] = item;
  // Add the to the list of ids of the category
  if (!collection.categories[item.category]) {
    collection.categories[item.category] = {
      id: item.category,
      title: unSnake(item.category), // These are stores snake_case in the db
      itemIds: [Number(item.id)],
    };
    // Add the new category to the column order
    collection.categoryOrder.push(item.category);
  } else {
    // @TODO Why this is getting doubled in the first place is something to look into
    if (!collection.categories[item.category].itemIds.includes(item.id)) {
      collection.categories[item.category].itemIds.push(Number(item.id));
    }
  }
};

const sortByOrder = (a, b) => {
  if (a.order > b.order) {
    return 1;
  } else if (a.order < b.order) {
    return -1;
  } else {
    return 0;
  }
};

const addInitialItems = (store, { items, objectType, collectionType }) => {
  // Get the nulls to the front
  items.sort(sortByOrder);
  const nextCollection = generateCollectionFromResponse({ items, collectionType });
  // Sort collection by order
  for (const categoryId in nextCollection.categories) {
    nextCollection.categories[categoryId].itemIds.sort((a, b) => {
      const c = nextCollection.items[a];
      const d = nextCollection.items[b];
      return sortByOrder(c, d);
    });
  }
  saveToStore({ store, objectType, collectionType, nextCollection });
  saveCountToStore({ store, objectType, collectionType, count: items.length });
};

const addNewItem = (store, { item, objectType, collectionType }) => {
  const accessor = getAccessor({ objectType, collectionType });
  const DEFAULT_ID = getDefaultId(collectionType);
  const nextCollection = { ...store.state[accessor] };

  nextCollection.categories[DEFAULT_ID].itemIds.push(item.id);
  item.order = nextCollection.categories[DEFAULT_ID].itemIds.length - 1;
  item.category = DEFAULT_ID;
  if (!nextCollection.items) nextCollection.items = {};
  nextCollection.items[item.id] = item;

  saveToStore({ store, objectType, collectionType, nextCollection });
  saveCountToStore({ store, objectType, collectionType, count: Object.keys(nextCollection.items).length });
};

/**
 * For both source and desintation, the structure is:
 * {
 *  droppableId: "Snake_Case_Title",
 *  index: number
 * }
 * Use this to
 */
const updateStructuredList = ({ list, source, destination, draggableId }) => {
  const sourceCategory = list.categories[source.droppableId];
  const nextSourceIds = Array.from(sourceCategory.itemIds);
  // Dropping to the same category
  if (source.droppableId === destination.droppableId) {
    // Remove from the source index
    nextSourceIds.splice(source.index, 1);
    // Add to the next position
    nextSourceIds.splice(destination.index, 0, draggableId);
    // Update the ids
    const nextSourceCategory = {
      ...sourceCategory,
      itemIds: nextSourceIds,
    };
    // Update the list
    const nextList = {
      ...list,
      categories: {
        ...list.categories,
        [nextSourceCategory.id]: nextSourceCategory,
      },
    };
    // Update the order of the items in the item hash
    nextSourceIds.forEach((id, order) => (nextList.items[id].order = order));
    return nextList;
    // Dropping to different categories
  } else {
    // Remove the source item
    let sourceIndex = source.index;
    if (!sourceIndex) {
      sourceIndex = nextSourceIds.indexOf(draggableId);
    }
    nextSourceIds.splice(sourceIndex, 1);
    // Update the source category
    const nextSourceCategory = {
      ...sourceCategory,
      itemIds: nextSourceIds,
    };
    const destinationCategory = list.categories[destination.droppableId];
    const nextDesintationIds = Array.from(destinationCategory.itemIds);
    // Add to the destination category
    nextDesintationIds.splice(destination.index || nextDesintationIds.length, 0, draggableId);
    const nextDesinationCategory = {
      ...destinationCategory,
      itemIds: nextDesintationIds,
    };
    // Update the list
    const nextList = {
      ...list,
      categories: {
        ...list.categories,
        [nextSourceCategory.id]: nextSourceCategory,
        [nextDesinationCategory.id]: nextDesinationCategory,
      },
    };
    // Update the order of the items in the item hash
    nextSourceIds.forEach((id, order) => (nextList.items[id].order = order));
    nextDesintationIds.forEach((id, order) => {
      nextList.items[id].order = order;
      nextList.items[id].category = destination.droppableId;
    });
    return nextList;
  }
};

const updateCollection = (store, { list, source, destination, draggableId, objectType, collectionType }) => {
  const nextCollection = updateStructuredList({ list, source, destination, draggableId });
  saveToStore({ store, objectType, collectionType, nextCollection });
};

const editById = (store, { id, newSearch, objectType, collectionType }) => {
  const accessor = getAccessor({ objectType, collectionType });
  const nextCollection = { ...store.state[accessor] };
  nextCollection.items[id] = newSearch;
  saveToStore({ store, objectType, collectionType, nextCollection });
};

const deleteById = (store, { id, objectType, collectionType }) => {
  const accessor = getAccessor({ objectType, collectionType });
  const nextCollection = {
    ...store.state[accessor],
  };
  // Null out the item in the hash
  delete nextCollection.items[id];
  // Remove it from the category it's a part of
  for (const categoryId in nextCollection.categories) {
    nextCollection.categories[categoryId].itemIds = nextCollection.categories[categoryId].itemIds.filter(
      (itemId) => Number(itemId) !== Number(id),
    );
  }
  saveToStore({ store, objectType, collectionType, nextCollection });
  saveCountToStore({ store, objectType, collectionType, count: Object.keys(nextCollection.items).length });
};

const addCategory = (store, { list, newCategory, objectType, collectionType }) => {
  const categoryId = newCategory.replace(/ /g, "_");
  const nextCategories = {
    ...list.categories,
    [categoryId]: {
      id: categoryId,
      title: newCategory,
      itemIds: [],
    },
  };
  const nextCategoryOrder = Array.from(list.categoryOrder);
  nextCategoryOrder.push(categoryId);
  const nextCollection = {
    ...list,
    categories: nextCategories,
    categoryOrder: nextCategoryOrder,
  };
  saveToStore({ store, objectType, collectionType, nextCollection });
};

const removeCategory = (store, { list, categoryId, objectType, collectionType }) => {
  const nextCategoryOrder = Array.from(list.categoryOrder).filter((id) => id !== categoryId);
  const nextCollection = {
    ...list,
    categoryOrder: nextCategoryOrder,
  };
  delete nextCollection.categories[categoryId];
  saveToStore({ store, objectType, collectionType, nextCollection });
};

const saveToStore = ({ store, objectType, collectionType, nextCollection }) => {
  switch (collectionType) {
    case COLLECTION_TYPES.SAVED_SEARCH:
      switch (objectType) {
        case SAVED_SEARCH_TYPES.PLAYER_CHARTS:
          store.setState({ savedPlayerCharts: nextCollection });
          break;
        case SAVED_SEARCH_TYPES.SEALED_WAX_CHARTS:
          store.setState({ savedSealedWaxCharts: nextCollection });
          break;
        case SAVED_SEARCH_TYPES.SALES_LOOKUP:
          store.setState({ savedSalesLookup: nextCollection });
          break;
        case SAVED_SEARCH_TYPES.SEALED_WAX:
          store.setState({ savedSealedWax: nextCollection });
          break;
        case SAVED_SEARCH_TYPES.SET_AND_YEAR:
          store.setState({ savedSetAndYear: nextCollection });
          break;
        case SAVED_SEARCH_TYPES.GRADE_RATIOS:
          store.setState({ savedGradeRatios: nextCollection });
          break;
        case SAVED_SEARCH_TYPES.VARIATION_RATIOS:
          store.setState({ savedVariationRatios: nextCollection });
          break;
        case SAVED_SEARCH_TYPES.PLAYER_RATIOS:
          store.setState({ savedPlayerRatios: nextCollection });
          break;
      }
      break;
    case COLLECTION_TYPES.FAVORITES:
      switch (objectType) {
        case FAVORITE_TYPES.CARD:
          store.setState({ savedFavoritesCards: nextCollection });
          break;
        case FAVORITE_TYPES.PLAYER:
          store.setState({ savedFavoritesPlayers: nextCollection });
          break;
        case FAVORITE_TYPES.SEALED_WAX:
          store.setState({ savedFavoritesSealedWax: nextCollection });
          break;
      }
      break;
  }
};

const saveCountToStore = ({ store, objectType, collectionType, count }) => {
  switch (collectionType) {
    case COLLECTION_TYPES.SAVED_SEARCH:
      switch (objectType) {
        case SAVED_SEARCH_TYPES.PLAYER_CHARTS:
          store.setState({ savedPlayerChartsCount: count });
          break;
        case SAVED_SEARCH_TYPES.SEALED_WAX_CHARTS:
          store.setState({ savedSealedWaxChartsCount: count });
          break;
        case SAVED_SEARCH_TYPES.SALES_LOOKUP:
          store.setState({ savedSalesLookupCount: count });
          break;
        case SAVED_SEARCH_TYPES.SEALED_WAX:
          store.setState({ savedSealedWaxCount: count });
          break;
        case SAVED_SEARCH_TYPES.SET_AND_YEAR:
          store.setState({ savedSetAndYearCount: count });
          break;
        case SAVED_SEARCH_TYPES.GRADE_RATIOS:
          store.setState({ savedGradeRatiosCount: count });
          break;
        case SAVED_SEARCH_TYPES.VARIATION_RATIOS:
          store.setState({ savedVariationRatiosCount: count });
          break;
        case SAVED_SEARCH_TYPES.PLAYER_RATIOS:
          store.setState({ savedPlayerRatiosCount: count });
          break;
      }
      break;
    case COLLECTION_TYPES.FAVORITES:
      switch (objectType) {
        case FAVORITE_TYPES.CARD:
          store.setState({ savedFavoritesCardsCount: count });
          break;
        case FAVORITE_TYPES.PLAYER:
          store.setState({ savedFavoritesPlayersCount: count });
          break;
        case FAVORITE_TYPES.SEALED_WAX:
          store.setState({ savedFavoritesSealedWaxCount: count });
          break;
      }
      break;
  }
};

const getAccessor = ({ objectType, collectionType }) => {
  switch (collectionType) {
    case COLLECTION_TYPES.SAVED_SEARCH:
      switch (objectType) {
        case SAVED_SEARCH_TYPES.PLAYER_CHARTS:
          return "savedPlayerCharts";
        case SAVED_SEARCH_TYPES.SEALED_WAX_CHARTS:
          return "savedSealedWaxCharts";
        case SAVED_SEARCH_TYPES.SALES_LOOKUP:
          return "savedSalesLookup";
        case SAVED_SEARCH_TYPES.SEALED_WAX:
          return "savedSealedWax";
        case SAVED_SEARCH_TYPES.SET_AND_YEAR:
          return "savedSetAndYear";
        case SAVED_SEARCH_TYPES.GRADE_RATIOS:
          return "savedGradeRatios";
        case SAVED_SEARCH_TYPES.VARIATION_RATIOS:
          return "savedVariationRatios";
        case SAVED_SEARCH_TYPES.PLAYER_RATIOS:
          return "savedPlayerRatios";
      }
      break;
    case COLLECTION_TYPES.FAVORITES:
      switch (objectType) {
        case FAVORITE_TYPES.CARD:
          return "savedFavoritesCards";
        case FAVORITE_TYPES.PLAYER:
          return "savedFavoritesPlayers";
        case FAVORITE_TYPES.SEALED_WAX:
          return "savedFavoritesSealedWax";
      }
  }
};

const getDefaultId = (collectionType) => {
  // @TODO this is brittle
  return collectionType === COLLECTION_TYPES.SAVED_SEARCH ? "Saved_Searches" : "Saved_Favorites";
};

// Hssssss.reverse()
const unSnake = (str) => str.replace(/_/g, " ");

const collectionActions = {
  addInitialItems,
  addNewItem,
  updateCollection,
  editById,
  deleteById,
  addCategory,
  removeCategory,
};

export { collectionActions, getAccessor };
