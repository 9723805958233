import getConfig from "next/config";
const { publicRuntimeConfig } = getConfig();

export default {
  page: (path) => {
    if (!publicRuntimeConfig.GOOGLE_ANALYTICS_GTAG || !window.gtag) {
      return console.warn("GA is disabled");
    }

    window.gtag("config", publicRuntimeConfig.GOOGLE_ANALYTICS_GTAG, {
      page_path: path,
    });
  },
  event: ({ action, params }) => {
    if (!publicRuntimeConfig.GOOGLE_ANALYTICS_GTAG || !window.gtag) {
      return console.warn("GA is disabled");
    }

    window.gtag("event", action, params);
  },
};
